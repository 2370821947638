body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

#videoContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
}

#bgVideo,
#fallbackGif {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  object-fit: cover;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#starsContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.star {
  position: absolute;
  width: 24px;
  height: 24px;
  pointer-events: none;
}

.star-shape {
  width: 20px;
  height: 20px;
}

.star-shape svg {
  width: 100%;
  height: 100%;
}

.star-dot {
  width: 4px;
  height: 4px;
  background-color: currentColor;
  border-radius: 50%;
  filter: blur(2px);
}

.star svg {
  width: 100%;
  height: 100%;
}
